.header {
  color: white;
  padding: 1rem 0;

  .title,
  .subtitle {
    text-align: center;
    margin: 0;
    user-select: none;
    cursor: default;
  }

  .subtitle {
    font-size: 1rem;
  }
}
