@import "../../scss/SharedStyles.scss";

.marquee {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: auto;

  @include scrollbar-style;
}
