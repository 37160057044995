.tiers {
  display: flex;
  flex-direction: column;
}

.toolbar {
  display: flex;
  width: 100%;
  height: 128px;
  margin-bottom: 1rem;
  padding-right: 0.25rem;

  @media screen and (min-width: 768px) {
    height: 108px;
  }
}

.notificaitonMessage {
  position: fixed;
  top: 0;
  color: white;
  width: 100vw;
  left: 0;
  min-height: 1rem;
  background-color: #338d46;
  padding: 1rem;
  text-align: center;
  z-index: 5;
}
