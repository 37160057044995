.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(#5f5f5f, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal {
  width: 100%;
  margin: 0 1rem;
  max-width: 500px;
  border: 2px solid #cccccc;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 1rem;
  background-color: #282c34;
  color: white;

  .title {
    font-size: 1.5rem;
    margin-top: 0;

    .titleIcon {
      margin-right: 0.5rem;
    }
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    .btn {
      border: 2px solid white;
      color: white;
      outline: 0;
      font-size: 1rem;
      border-radius: 4px;
      cursor: pointer;
      height: 3rem;
      width: 100px;
      transition: all 500ms;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &.submit {
        background-color: #338d46;
      }

      &.cancel {
        background-color: #c9d3de;
        color: #4a4a4a;
      }
    }
  }
}
