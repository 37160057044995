.card {
  height: 96px;
  border-radius: 4px;
  margin-right: 0.25rem;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0;

  .image {
    height: 100%;
    width: auto;
  }

  &.common {
    border: 2px solid #3098bd;
  }

  &.rare {
    border: 2px solid #3098bd;
  }

  &.epic {
    border: 2px solid #9623d8;
  }

  &.legendary {
    border: 2px solid #c59d2c;
  }

  &.isDragging {
    opacity: 0.5;
  }

  .remove {
    border-radius: 25px;
    width: 1.5rem;
    height: 1.5rem;
    border: 0;
    outline: 0;
    font-size: 1rem;
    margin-bottom: 1rem;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #ff4122;
    cursor: pointer;
    position: absolute;
    top: -2rem;
    z-index: 1;
    visibility: hidden;
    transition: all 500ms;
  }

  &:hover {
    .remove {
      top: 0.25rem;
      visibility: initial;
    }
  }
}
