* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  background-color: #282c34;
}
