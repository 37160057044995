@mixin scrollbar-style {
  &::-webkit-scrollbar {
    height: 1.5rem;
    z-index: 2;
    cursor: pointer;

    @media screen and (min-width: 768px) {
      height: 0.5rem;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border: 1px solid slategrey;
    z-index: 2;
    border-radius: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    cursor: pointer;
  }

  scrollbar-color: slategrey darkgrey;
  scrollbar-width: thin;
}
