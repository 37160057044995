.footer {
  color: white;
  margin: 0;
  padding: 2rem 1rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;

  p {
    margin: 0 0 0.25rem;

    .link,
    .link:hover,
    .link:visited {
      color: white;
    }
  }

  .donateRow {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 0.5rem 0;

    .donateForm {
      display: flex;
      align-items: center;
      margin-right: 0.25rem;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 1rem 0 0;
  }
}
