@import "../../scss/SharedStyles.scss";

.tier {
  display: flex;
  border-bottom: 2px solid white;
  height: 126px;
  padding-right: 0.25rem;

  @media screen and (min-width: 768px) {
    height: 108px;
  }

  &:first-child {
    border-top: 2px solid white;
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    color: #282c34;
    border-right: 2px solid white;
    flex: 1 1 75px;
    max-width: 75px;
    min-width: 75px;
    font-weight: bold;
    user-select: none;
    cursor: default;

    &.s {
      background-color: #e46668;
    }

    &.a {
      background-color: #eb9f40;
    }

    &.b {
      background-color: #eace70;
    }

    &.c {
      background-color: #d3e79b;
    }

    &.d {
      background-color: #c1e5ce;
    }

    &.e {
      background-color: #cddfed;
    }

    &.f {
      background-color: #e5e4f6;
    }
  }

  .cardHolder {
    display: flex;
    align-items: flex-start;
    flex: 1 1;
    transition: all 500ms;
    padding: 0 0.25rem;
    overflow: auto hidden;

    &:hover,
    &.isOver {
      background-color: #3c414c;
    }

    @include scrollbar-style;
  }
}
